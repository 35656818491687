@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}

h1,
.h1 {
  display: block;
  font-size: 30px;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h2,
.h2 {
  display: block;
  font-size: 26px;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h3,
.h3 {
  display: block;
  font-size: 20px;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h4,
.h4 {
  display: block;
  font-size: 18px;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h5,
.h5 {
  display: block;
  font-size: 14px;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h6,
.h6 {
  display: block;
  font-size: 10px;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.custom-scrollbar::-webkit-scrollbar {
  width: .3em;
  height: .3rem;
  border-radius: 25px;
  background-color: rgb(176, 176, 176);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background-color: rgb(255, 255, 255);
}
.Lineargradient{
  background-image: linear-gradient(red blue);
} 
@layer components {
  html {
    font-family: Poppins;
  }

  label {
    @apply text-[16px] text-white cursor-pointer hover:text-[#24baef] duration-300
  }

  p {
    @apply text-[#8D8D8D] text-[16px]
  }

  .Icons {
    @apply w-5 h-5 object-contain cursor-pointer
  }

  .Icon {
    @apply w-4 h-4 object-contain cursor-pointer
  }

  .carousel-custom-arrows .carousel-control-prev-icon,
  .carousel-custom-arrows .carousel-control-next-icon {
    background-color: '#24baef';
  }
  
}